import React, { useState, useEffect, useCallback, useRef } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { get, getAndConcat } from '../../reducers/actions/project.js'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SearchBanner from '../../components/searchBanner'
import ListItem from '../../components/listItem'
import styles from './styles.module.css'

const Projects = () => {

  const projects = useSelector(state => state.projectReducer.getProjects)
  const dispatch = useDispatch()
  const [isFirstRender, setIsFirstRender] = useState(true)

  const queryParams = {
    page: 1,
    limit: 40
  }

  useEffect(() => {
    if(isFirstRender) {
      get(dispatch)(queryParams)
      setIsFirstRender(false)
    }
  })

  const loadDocuments = entry => {
    if (entry.intersectionRatio > 0) {
      if(
        !projects.loading &&
        projects.response &&
        projects.response.totalPages > projects.response.page
      ) {
        getAndConcat(dispatch)({...queryParams, page: projects.response.page+1})
        }
    }
  }
 
  const scrollObserver = useCallback(node => {
    const observer =  new IntersectionObserver(entries => entries.forEach(loadDocuments))
    observer.observe(node)
  })
  const boundaryRef = useRef(null);
  
  useEffect(() => {
    if (boundaryRef.current) {
      scrollObserver(boundaryRef.current)
    }
  }, [projects, boundaryRef])

  const list = projects.response ? projects.response.docs.map(project => ({
    title: project.name,
    url: `/project-details?id=${project._id}`,
    text: project.summary,
    highlights: {
      'Proyectos creados': project.user.nProjects,
      'Calificaciones': project.user.score,
      'Presupuesto': project.budget,
    },
    actions: []
  })): []

  const {page, limit} = projects.response ? projects.response : queryParams

  return (
    <Layout>
      <SEO title="Projects" />
      <h1>Proyectos</h1>
      <SearchBanner onlyCategorySearch/>
      <div className={styles.list}>
        {list.map((project, index) => (
         <React.Fragment key={index}>
            <ListItem key={index} {...project} />
            {index === (page*limit/2) && <div ref={boundaryRef}></div>}
          </React.Fragment>
        ))}
      </div>
    </Layout>
  )
}

export default Projects
